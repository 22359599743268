import type {ReactElement} from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

import Button from '@mui/material/Button/Button.js';
import FormControl from '@mui/material/FormControl/FormControl.js';
import TextField from '@mui/material/TextField/TextField.js';
import Typography from '@mui/material/Typography/Typography.js';

import {
    NOTIFICATION,
    useNotificationContext
} from '@/components/notification/SnackbarNotification.js';

const emailValidationRegex: RegExp =
    /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;

/**
 * @param props.onNext callback
 * @param props.onBack callback
 * @param props.headerAddon Optional.
 * @returns
 */
export default function RegisterAccount(props: {
    onNext: (email: string) => Promise<void>;
    onBack: () => void;
    email?: string;
    headerAddon?: ReactElement;
}): ReactElement {
    const i18n = useTranslation();
    const [email, setEmail] = useState<string>(props.email ? props.email : '');
    const [emailConfirmation, setEmailConfirmation] = useState<string>(
        props.email ? props.email : ''
    );
    const {setNotificationMessage, setNotificationType} = useNotificationContext();

    async function validate() {
        if (email === '' || emailConfirmation === '') {
            setNotificationMessage('flexibel_registration.register.account.error.mustFillFields');
            setNotificationType(NOTIFICATION.Error);
        } else if (email !== emailConfirmation) {
            setNotificationMessage('flexibel_registration.register.account.error.emailsMustMatch');
            setNotificationType(NOTIFICATION.Error);
        } else if (!emailValidationRegex.test(email)) {
            setNotificationMessage('flexibel_registration.register.account.error.emailInvalid');
            setNotificationType(NOTIFICATION.Error);
        } else {
            await props.onNext(email.toLowerCase());
        }
    }

    return (
        <>
            {props.headerAddon}
            <Typography className="register-header" component="h3">
                {i18n.t('flexibel_registration.register.header')}
            </Typography>
            <div className="register-content-container">
                <div className="register-content space">
                    <FormControl className="field">
                        <Typography className="register-field-label" component="div">
                            {i18n.t('flexibel_registration.register.account.label.email')}:
                        </Typography>
                        <TextField
                            type="email"
                            className="input"
                            variant="outlined"
                            placeholder="mail@adresse.de"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            onKeyPress={async event => {
                                if (event.key === 'Enter') {
                                    await validate();
                                }
                            }}
                        />
                    </FormControl>
                    <FormControl className="field">
                        <Typography className="register-field-label" component="div">
                            {i18n.t(
                                'flexibel_registration.register.account.label.emailConfirmation'
                            )}
                            :
                        </Typography>
                        <TextField
                            type="email"
                            className="input"
                            variant="outlined"
                            placeholder="mail@adresse.de"
                            value={emailConfirmation}
                            onChange={e => setEmailConfirmation(e.target.value)}
                            onKeyPress={async event => {
                                if (event.key === 'Enter') {
                                    await validate();
                                }
                            }}
                        />
                    </FormControl>
                </div>
            </div>
            <div className="register-buttons">
                <Button className="next" onClick={validate}>
                    {i18n.t('flexibel_registration.buttons.common.next')}
                </Button>
                <Button onClick={props.onBack}>
                    {i18n.t('flexibel_registration.buttons.common.back')}
                </Button>
            </div>
        </>
    );
}
