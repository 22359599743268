import type {ReactElement, ReactNode} from 'react';
import {isMobile} from 'react-device-detect';

import desktopBackgroundImage from '@/flexibel/resources/images/desktop_background.png';
import mobileBackgroundImage from '@/flexibel/resources/images/mobile_background.png';
import bigLogoImage from '@/flexibel/resources/images/big_logo.png';
import smallLogoImage from '@/flexibel/resources/images/small_logo.png';

import './RegistrationBackground.css';

export default function RegistrationBackground(props: {children: ReactNode}): ReactElement {
    return (
        <div
            className="registration-background"
            style={{
                backgroundImage: `url(${isMobile ? mobileBackgroundImage : desktopBackgroundImage})`
            }}
        >
            <img
                src={isMobile ? smallLogoImage : bigLogoImage}
                className="registration-background-logo"
                alt="logo"
            />
            {props.children}
        </div>
    );
}
