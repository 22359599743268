import {useState} from 'react';
import type {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

import Button from '@mui/material/Button/Button.js';
import Typography from '@mui/material/Typography/Typography.js';
import FormControl from '@mui/material/FormControl/FormControl.js';
import TextField from '@mui/material/TextField/TextField.js';

/**
 * @param props.onNext callback
 * @param props.onBack callback
 * @param props.keys Optional.
 * @param props.headerAddon Optional.
 * @returns
 */
export default function RegisterImportKeys(props: {
    onNext: (keys?: string) => void;
    onBack: () => void;
    keys?: string;
    headerAddon?: ReactElement;
}): ReactElement {
    const i18n = useTranslation();
    const [keys, setKeys] = useState<string>(props.keys ?? '');

    function validate() {
        props.onNext(keys === '' ? undefined : keys);
    }

    return (
        <>
            {props.headerAddon}
            <Typography className="register-header" component="h3">
                {i18n.t('flexibel_registration.register.header')}
            </Typography>
            <div className="register-content-container">
                <div className="register-content space">
                    <FormControl className="field">
                        <Typography className="register-field-label" component="div">
                            {i18n.t('flexibel_registration.register.keys.label')}
                        </Typography>
                        <TextField
                            type="text"
                            className="input"
                            variant="outlined"
                            value={keys}
                            onChange={event => setKeys(event.target.value)}
                            onKeyPress={event => {
                                if (event.key === 'Enter') {
                                    validate();
                                }
                            }}
                        />
                    </FormControl>
                </div>
            </div>
            <div className="register-buttons">
                <Button className="next" onClick={validate}>
                    {i18n.t('flexibel_registration.buttons.common.next')}
                </Button>
                <Button onClick={props.onBack}>
                    {i18n.t('flexibel_registration.buttons.common.back')}
                </Button>
            </div>
        </>
    );
}
