import {createAccess} from '@refinio/one.core/lib/access.js';
import {createMessageBus} from '@refinio/one.core/lib/message-bus.js';
import type {Person} from '@refinio/one.core/lib/recipes.js';
import {SET_ACCESS_MODE} from '@refinio/one.core/lib/storage-base-common.js';
import type {HexString} from '@refinio/one.core/lib/util/arraybuffer-to-and-from-hex-string.js';
import type {SHA256Hash, SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import {OEvent} from '@refinio/one.models/lib/misc/OEvent.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type ProfileModel from '@refinio/one.models/lib/models/Leute/ProfileModel.js';
import {Model} from '@refinio/one.models/lib/models/Model.js';
import type {OneInstanceEndpoint} from '@refinio/one.models/lib/recipes/Leute/CommunicationEndpoints.js';
import type {Profile} from '@refinio/one.models/lib/recipes/Leute/Profile.js';

const MessageBus = createMessageBus('ClinicModels');

export default class AdminModel extends Model {
    private leuteModel: LeuteModel;
    private rootOfTrustPublicSignKey: HexString;
    private checkInstanceForAdminDisconnectListener: (() => void) | undefined;
    private adminSharingDisconnectListener: (() => void) | undefined;
    private adminPersonId: undefined | SHA256IdHash<Person>;

    public onAdminChange = new OEvent<() => void | Promise<void>>();

    constructor(leuteModel: LeuteModel, rootOfTrustPublicSignKey: HexString) {
        super();
        this.leuteModel = leuteModel;
        this.rootOfTrustPublicSignKey = rootOfTrustPublicSignKey;
        this.adminPersonId = undefined;
    }

    public async init(): Promise<void> {
        MessageBus.send('debug', 'AdminModel - init - start');
        await this.findAdminPersonId();

        if (this.adminPersonId === undefined) {
            MessageBus.send('debug', 'AdminModel - init - adminWaitDisconnectListener init');
            this.checkInstanceForAdminDisconnectListener = this.leuteModel.onNewOneInstanceEndpoint(
                this.checkInstanceForAdmin.bind(this)
            );
        } else {
            await this.trustAndShareAdmin();
            this.onAdminChange.emit();
        }
    }

    // required by super
    // eslint-disable-next-line @typescript-eslint/require-await
    public async shutdown(): Promise<void> {
        if (this.checkInstanceForAdminDisconnectListener !== undefined) {
            this.checkInstanceForAdminDisconnectListener();
        }
        this.checkInstanceForAdminDisconnectListener = undefined;

        if (this.adminSharingDisconnectListener !== undefined) {
            this.adminSharingDisconnectListener();
        }
        this.adminSharingDisconnectListener = undefined;
    }

    public async isAdmin(personId?: SHA256IdHash<Person>): Promise<boolean> {
        if (this.rootOfTrustPublicSignKey === '') {
            return false;
        }

        const targetPersonId = personId ?? (await this.leuteModel.myMainIdentity());

        return this.adminPersonId !== undefined && this.adminPersonId === targetPersonId;
    }

    public async isProfileTrusted(profileHash: SHA256Hash<Profile>): Promise<boolean> {
        if (this.rootOfTrustPublicSignKey === '') {
            return false;
        }
        const certs = await this.leuteModel.trust.getCertificatesOfType(
            profileHash,
            'AffirmationCertificate'
        );

        for (const cert of certs) {
            const trust = await this.leuteModel.trust.findKeyThatVerifiesSignature(cert.signature);

            if (trust === undefined) {
                continue;
            }

            if (trust.key === this.rootOfTrustPublicSignKey) {
                return true;
            }
        }

        return false;
    }

    public getAdminPersonId(): SHA256IdHash<Person> | undefined {
        return this.adminPersonId;
    }

    /** ***** Private ***** **/

    private async findAdminPersonId(): Promise<SHA256IdHash<Person> | undefined> {
        if (this.rootOfTrustPublicSignKey === '') {
            return undefined;
        }

        if (this.adminPersonId === undefined) {
            const everyone = [...(await this.leuteModel.others()), await this.leuteModel.me()];

            for (const someone of everyone) {
                for (const profile of await someone.profiles()) {
                    if (this.profileContainsAdminKey(profile)) {
                        MessageBus.send(
                            'debug',
                            'AdminModel - findAdminPersonId - found adminPersonId',
                            profile.personId
                        );
                        this.adminPersonId = profile.personId;
                        return this.adminPersonId;
                    }
                }
            }
        }

        return this.adminPersonId;
    }

    private profileContainsAdminKey(profile: ProfileModel): boolean {
        if (this.rootOfTrustPublicSignKey === '') {
            return false;
        }

        return profile
            .descriptionsOfType('SignKey')
            .some(signKey => signKey.key === this.rootOfTrustPublicSignKey);
    }

    public async shareWith(personId: SHA256IdHash<Person>): Promise<void> {
        if (this.rootOfTrustPublicSignKey === '') {
            return;
        }

        if (this.adminPersonId === undefined) {
            return;
        }

        const profile = await this.leuteModel.getMainProfile(this.adminPersonId);

        await createAccess([
            {
                id: profile.idHash,
                person: [personId],
                group: [],
                mode: SET_ACCESS_MODE.ADD
            }
        ]);
    }

    private async startSharingAdmin(): Promise<Promise<void>> {
        if (this.rootOfTrustPublicSignKey === '') {
            return;
        }

        if (this.adminSharingDisconnectListener !== undefined) {
            return;
        }

        const others = await this.leuteModel.others();

        for (const other of others) {
            for (const identity of other.identities()) {
                await this.shareWith(identity);
            }
        }

        this.adminSharingDisconnectListener = this.leuteModel.onNewOneInstanceEndpoint(i =>
            this.shareWith(i.personId)
        );
    }

    private async ensureTrustAdmin(): Promise<void> {
        if (this.rootOfTrustPublicSignKey === '') {
            return;
        }

        const me = await this.leuteModel.me();

        if (this.adminPersonId === undefined || me.identities().includes(this.adminPersonId)) {
            // no need to trust our own identity or undefined
            return;
        }

        const someone = await this.leuteModel.getSomeone(this.adminPersonId);

        if (someone === undefined) {
            // typescript lint avoidance, should not happen
            throw new Error(
                `Admin personId received, but someone is not found in Leute Model ${this.adminPersonId}`
            );
        }

        let newTrust = false;

        for (const profileModel of await someone.profiles()) {
            if (
                profileModel
                    .descriptionsOfType('SignKey')
                    .some(k => k.key === this.rootOfTrustPublicSignKey)
            ) {
                if (profileModel.loadedVersion === undefined) {
                    // typescript lint avoidance, should not happen
                    throw new Error(
                        `Profile id does not have a loaded version ${profileModel.idHash}`
                    );
                }

                newTrust = true;
                await this.leuteModel.trust.certify('TrustKeysCertificate', {
                    profile: profileModel.loadedVersion
                });
            }
        }

        if (newTrust) {
            await this.leuteModel.trust.refreshCaches(); // Just a hack until we have a better way of refresh
        }
    }

    private async trustAndShareAdmin(): Promise<void> {
        if (this.adminPersonId) {
            MessageBus.send('debug', 'AdminModel - found admin', this.adminPersonId);

            await this.ensureTrustAdmin();
            await this.startSharingAdmin();
        }
    }

    private async checkInstanceForAdmin(endpoint: OneInstanceEndpoint): Promise<void> {
        if (this.rootOfTrustPublicSignKey === '') {
            return;
        }

        const someone = await this.leuteModel.getSomeone(endpoint.personId);

        if (someone === undefined) {
            return;
        }

        for (const profile of await someone.profiles()) {
            if (this.profileContainsAdminKey(profile)) {
                this.adminPersonId = endpoint.personId;
                break;
            }
        }

        if (this.adminPersonId === undefined) {
            MessageBus.send(
                'debug',
                'AdminModel - adminWaitDisconnectListener - not admin',
                endpoint
            );
            return;
        }

        if (this.checkInstanceForAdminDisconnectListener !== undefined) {
            this.checkInstanceForAdminDisconnectListener();
        }
        this.checkInstanceForAdminDisconnectListener = undefined;

        await this.trustAndShareAdmin();
        this.onAdminChange.emit();
    }
}
