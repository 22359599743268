import type {ReactElement} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import Button from '@mui/material/Button/Button.js';
import Typography from '@mui/material/Typography/Typography.js';

import landingHeaderImage from '@/flexibel/resources/images/landing_header.png';

import '../LandingPage.css';

/**
 * @param props.onNext callback
 * @returns
 */
export default function RegisterStart(props: {onNext: () => void}): ReactElement {
    const navigate = useNavigate();
    const i18n = useTranslation();

    return (
        <>
            <div className="landing-header">
                <img src={landingHeaderImage} alt="landing-header" />
            </div>
            <div className="register-content-container">
                <div className="register-content space">
                    <div className="register-info">
                        <Typography className="login-data" component="h3">
                            {i18n.t('flexibel_registration.register.startheader1')}
                        </Typography>
                    </div>
                    <div className="register-info">
                        <Typography className="login-data" component="h3">
                            {i18n.t('flexibel_registration.register.startheader2')}
                        </Typography>
                    </div>
                </div>
            </div>
            <div className="register-buttons">
                <Button className="next" onClick={props.onNext}>
                    {i18n.t('flexibel_registration.buttons.common.next')}
                </Button>
                <Button onClick={() => navigate(-1)}>
                    {i18n.t('flexibel_registration.buttons.common.back')}
                </Button>
            </div>
        </>
    );
}
