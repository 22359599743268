import type {Person} from '@refinio/one.core/lib/recipes.js';
import type {SHA256Hash, SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import {Model} from '@refinio/one.models/lib/models/Model.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import {createAccess} from '@refinio/one.core/lib/access.js';
import {SET_ACCESS_MODE} from '@refinio/one.core/lib/storage-base-common.js';
import {createMessageBus} from '@refinio/one.core/lib/message-bus.js';
import type {Profile} from '@refinio/one.models/lib/recipes/Leute/Profile.js';

import type PatientModel from '@/model/roles/PatientModel.js';
import type PhysicianModel from '@/model/roles/PhysicianModel.js';

const MessageBus = createMessageBus('ClinicModels');

export default class SharingModel extends Model {
    private leuteModel: LeuteModel;
    private patientModel: PatientModel;
    private physicianModel: PhysicianModel;
    private peers: Array<SHA256IdHash<Person>>;
    private patientIdentity: SHA256IdHash<Person> | undefined;

    private newInstanceRoleSharingDisconnectListener: (() => void) | undefined;
    private waitForCurrentPhysicianDisconnectListener: (() => void) | undefined;
    private waitForCurrentPatientDisconnectListener: (() => void) | undefined;

    constructor(
        leuteModel: LeuteModel,
        patientModel: PatientModel,
        physicianModel: PhysicianModel
    ) {
        super();
        this.leuteModel = leuteModel;
        this.patientModel = patientModel;
        this.physicianModel = physicianModel;
        this.peers = [];
    }

    public async init(options?: {
        patientIdentity?: SHA256IdHash<Person>;
        peerIds?: Array<SHA256IdHash<Person>>;
        onPhysician?: () => Promise<void>;
    }): Promise<void> {
        this.patientIdentity = options?.patientIdentity;
        this.peers = options?.peerIds ?? [];

        this.newInstanceRoleSharingDisconnectListener = this.leuteModel.onNewOneInstanceEndpoint(
            async endpoint => {
                if (this.peers.includes(endpoint.personId)) {
                    return;
                }

                if (await this.patientModel.isPatient(this.patientIdentity)) {
                    return this.sharePatientWithPeers(this.patientIdentity);
                }

                if (await this.physicianModel.isPhysician()) {
                    return this.shareCurrentPhysicianWithPeers();
                }
            }
        );

        await this.onCurrentPhysician(async () => {
            if (options !== undefined && options.onPhysician !== undefined) {
                await options.onPhysician();
            }
            await this.shareCurrentPhysicianWithPeers();
        });

        await this.onCurrentPatient(async () => {
            await this.sharePatientWithPeers(this.patientIdentity);
        });
    }

    // required by extended model
    // eslint-disable-next-line @typescript-eslint/require-await
    public async shutdown(): Promise<void> {
        this.clearListeners();
        this.peers = [];
    }

    // **** PRIVATE **** //

    private clearListeners(): void {
        if (this.waitForCurrentPhysicianDisconnectListener !== undefined) {
            this.waitForCurrentPhysicianDisconnectListener();
        }
        this.waitForCurrentPhysicianDisconnectListener = undefined;

        if (this.newInstanceRoleSharingDisconnectListener !== undefined) {
            this.newInstanceRoleSharingDisconnectListener();
        }
        this.newInstanceRoleSharingDisconnectListener = undefined;
    }

    private async onCurrentPatient(cb: () => Promise<void>): Promise<void> {
        if (await this.patientModel.isPatient(this.patientIdentity)) {
            MessageBus.send('debug', 'ClinicSharing - onCurrentPatient - current user is patient');
            await cb();
        } else if (this.waitForCurrentPatientDisconnectListener === undefined) {
            MessageBus.send(
                'debug',
                'ClinicSharing - onCurrentPatient - current user is not patient, init onPatientChange listener for current user'
            );
            // wait for current user to become patient to share with Peers
            this.waitForCurrentPatientDisconnectListener = this.patientModel.onPatientChange(
                async () => {
                    if (!(await this.patientModel.isPatient(this.patientIdentity))) {
                        return;
                    }

                    MessageBus.send(
                        'debug',
                        'ClinicSharing - onCurrentPatient - onPatientChange listener - current user became patient'
                    );
                    await cb();
                    this.clearListeners();
                }
            );
        }
    }

    private async sharePatientWithPeers(patientId?: SHA256IdHash<Person>): Promise<void> {
        const someone = patientId
            ? await this.leuteModel.getSomeone(patientId)
            : await this.leuteModel.me();

        if (someone === undefined) {
            throw new Error(
                `Unable to get ${patientId ? `Someone with ${patientId}` : 'my Someone'}`
            );
        }

        for (const identity of someone.identities()) {
            const certificatesData = await this.leuteModel.trust.getCertificatesOfType(
                identity,
                'RelationCertificate'
            );
            const certificateData = certificatesData.find(
                data =>
                    data !== undefined &&
                    this.patientModel.isPatientCertificate(data.certificate, identity)
            );

            if (certificateData === undefined) {
                continue;
            }

            const mainProfile = (await someone.profiles()).find(
                profile => profile.profileId === 'default' && profile.personId === identity
            );

            if (mainProfile === undefined) {
                throw new Error('Unable to get getMyMainProfile');
            }

            await this.shareWithPeers(
                [mainProfile.idHash],
                [certificateData.certificateHash, certificateData.signatureHash]
            );
        }
    }

    private async onCurrentPhysician(cb: () => Promise<void>): Promise<void> {
        if (await this.physicianModel.isPhysician()) {
            MessageBus.send(
                'debug',
                'ClinicSharing - onCurrentPhysician - current user is physician'
            );
            await cb();
        } else if (this.waitForCurrentPhysicianDisconnectListener === undefined) {
            MessageBus.send(
                'debug',
                'ClinicSharing - onCurrentPhysician - current user is not physician, init onPhysiciansChange listener for current user'
            );
            // wait for current user to become physician to share with Peers
            this.waitForCurrentPhysicianDisconnectListener = this.physicianModel.onPhysiciansChange(
                async () => {
                    if (!(await this.physicianModel.isPhysician())) {
                        return;
                    }

                    MessageBus.send(
                        'debug',
                        'ClinicSharing - onCurrentPhysician - onPhysiciansChange listener - current user became physician'
                    );
                    await cb();
                    this.clearListeners();
                }
            );
        }
    }

    private async shareCurrentPhysicianWithPeers(): Promise<void> {
        const physicianPersonId = await this.leuteModel.myMainIdentity();
        const certificateData =
            await this.physicianModel.getValidPhysicianCertificateData(physicianPersonId);

        if (certificateData === undefined) {
            return;
        }

        const mainProfile = await this.leuteModel.getMainProfile(physicianPersonId);
        await this.shareWithPeers(
            [mainProfile.idHash],
            [certificateData.certificate.hash, certificateData.signature.hash]
        );
    }

    private async shareWithPeers(
        idHashes: Array<SHA256IdHash<Profile>>,
        hashes: Array<SHA256Hash>
    ): Promise<void> {
        for (const idHash of idHashes) {
            await createAccess([
                {
                    id: idHash,
                    person: this.peers,
                    group: [],
                    mode: SET_ACCESS_MODE.ADD
                }
            ]);
        }
        for (const hash of hashes) {
            await createAccess([
                {
                    object: hash,
                    person: this.peers,
                    group: [],
                    mode: SET_ACCESS_MODE.ADD
                }
            ]);
        }
    }
}
