import type {ReactElement} from 'react';
import {Route, Routes} from 'react-router';

import type {FlexibelRegistrationQuestionnaire} from '@/flexibel/hooks/questionnaire/flexibelRegistration.js';
import RegistrationBackground from '@/flexibel/components/registrationBackground/RegistrationBackground.js';
import RegistrationContentBubble from '@/flexibel/components/registrationBackground/RegistrationContentBubble.js';

import WelcomePage from './WelcomePage.js';
import Login from './login/Login.js';
import RegisterRouter from './registration/RegisterRouter.js';

import './LandingPage.css';

export default function LandingPageRouter(props: {
    onLogin: (email: string, password: string) => Promise<void>;
    onRegister: (email: string, name: string, password: string, keys?: string) => Promise<void>;
    isRegistered: (email: string) => Promise<boolean>;
    registrationQuestionnaire: FlexibelRegistrationQuestionnaire;
}): ReactElement {
    return (
        <RegistrationBackground>
            <RegistrationContentBubble>
                <Routes>
                    <Route index element={<WelcomePage />} />
                    <Route path="login" element={<Login onLogin={props.onLogin} />} />
                    <Route
                        path="register/*"
                        element={
                            <RegisterRouter
                                onRegister={props.onRegister}
                                isRegistered={props.isRegistered}
                                registrationQuestionnaire={props.registrationQuestionnaire}
                            />
                        }
                    />
                    <Route path="*" element={<WelcomePage />} />
                </Routes>
            </RegistrationContentBubble>
        </RegistrationBackground>
    );
}
