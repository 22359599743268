import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import {type SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import type {Person} from '@refinio/one.core/lib/recipes.js';
import {Model} from '@refinio/one.models/lib/models/Model.js';
import {objectEvents} from '@refinio/one.models/lib/misc/ObjectEventDispatcher.js';
import {OEvent} from '@refinio/one.models/lib/misc/OEvent.js';
import type {RelationCertificate} from '@refinio/one.models/lib/recipes/Certificates/RelationCertificate.js';
import {
    getObject,
    type UnversionedObjectResult
} from '@refinio/one.core/lib/storage-unversioned-objects.js';
import type {OneInstanceEndpoint} from '@refinio/one.models/lib/recipes/Leute/CommunicationEndpoints.js';
import type {Signature} from '@refinio/one.models/lib/recipes/SignatureRecipes.js';
import type GroupModel from '@refinio/one.models/lib/models/Leute/GroupModel.js';
import {createMessageBus} from '@refinio/one.core/lib/message-bus.js';

import {getGroup, getPersonIdsForRole} from './utils.js';

const MessageBus = createMessageBus('ClinicModels');

export default class PatientModel extends Model {
    private static patientsGroupName: string;

    private relation: string;
    private app: string;

    public static getPatientsGroupName(): string {
        // Getter for static access
        return PatientModel.patientsGroupName;
    }

    private leuteModel: LeuteModel;
    private waitForPatientDisconnectListeners: Array<() => void>;

    public onPatientChange = new OEvent<() => void | Promise<void>>();

    constructor(leuteModel: LeuteModel, patientsGroupName: string, relation: string, app: string) {
        super();
        this.leuteModel = leuteModel;
        this.waitForPatientDisconnectListeners = [];
        this.relation = relation;
        this.app = app;
        PatientModel.patientsGroupName = patientsGroupName;
    }

    public async init(): Promise<void> {
        // sync patients group
        const patients = await getPersonIdsForRole(this.leuteModel, this.isPatient.bind(this));

        if (patients.length > 0) {
            await this.addToPatientsGroup(patients, true);
        }
        this.waitForNewPatient();
    }

    // required by extended model
    // eslint-disable-next-line @typescript-eslint/require-await
    public async shutdown(): Promise<void> {
        for (const waitForPatientDisconnectListener of this.waitForPatientDisconnectListeners) {
            waitForPatientDisconnectListener();
        }
        this.waitForPatientDisconnectListeners = [];
    }

    public async isPatient(personId?: SHA256IdHash<Person>): Promise<boolean> {
        const targetPersonId = personId ?? (await this.leuteModel.myMainIdentity());

        const certificatesData = await this.leuteModel.trust.getCertificatesOfType(
            targetPersonId,
            'RelationCertificate'
        );

        for (const certificateData of certificatesData) {
            if (this.isPatientCertificate(certificateData.certificate, targetPersonId)) {
                return true;
            }
        }

        return false;
    }

    public async setAsPatient(personId: SHA256IdHash<Person>): Promise<void> {
        if (!(await this.isPatient(personId))) {
            const myMainIdentity = await this.leuteModel.myMainIdentity();
            await this.leuteModel.trust.certify(
                'RelationCertificate',
                {
                    person1: personId,
                    person2: myMainIdentity,
                    relation: this.relation,
                    app: this.app
                },
                myMainIdentity
            );
            this.onPatientChange.emit();
        }
    }

    public isPatientCertificate(
        certificate: RelationCertificate,
        person?: SHA256IdHash<Person>
    ): boolean {
        return (
            certificate.app === this.app &&
            certificate.relation === this.relation &&
            (person === undefined || certificate.person1 === person)
        );
    }

    public async getPatientsGroup(): Promise<GroupModel> {
        return getGroup(PatientModel.patientsGroupName);
    }

    /** ***** Private ***** **/

    private waitForNewPatient(): void {
        if (this.waitForPatientDisconnectListeners.length > 0) {
            return;
        }

        MessageBus.send('debug', 'PatientModel - emitOnNewPatient - listeners init');
        this.waitForPatientDisconnectListeners.push(
            objectEvents.onUnversionedObject(
                this.checkForPatientSignature.bind(this),
                'PatientModel: wait for sharing',
                'Signature'
            )
        );

        this.waitForPatientDisconnectListeners.push(
            this.leuteModel.onNewOneInstanceEndpoint(async (endpoint: OneInstanceEndpoint) => {
                // if not patient that is either
                // the person is not a patient or
                // we have not received his certificate
                // see other waitForPatientDisconnectListeners
                // that handle those cases
                if (!(await this.isPatient(endpoint.personId))) {
                    MessageBus.send(
                        'debug',
                        'PatientModel - emitOnNewPatient - new instance listener - not patient',
                        endpoint
                    );
                    return;
                }
                // also emits on new patient
                await this.addToPatientsGroup([endpoint.personId]);
            })
        );
    }

    private async addToPatientsGroup(
        personIds: Array<SHA256IdHash<Person>>,
        emitChangeNonEmptyGroup: boolean = false
    ): Promise<boolean> {
        const patientsGroup = await this.getPatientsGroup();
        const me = await this.leuteModel.me();
        const myIndentitis = me.identities();
        let addedNew = false;
        let addedNewTrust = false;

        for (const personId of personIds) {
            if (patientsGroup.persons.includes(personId)) {
                continue;
            }

            if (!myIndentitis.includes(personId)) {
                const someone = await this.leuteModel.getSomeone(personId);

                if (someone === undefined) {
                    throw new Error(`Could not find someone with personId ${personId}`);
                }

                for (const profile of await someone.profiles()) {
                    if (profile.loadedVersion === undefined) {
                        // typescript lint avoidance, should not be here
                        throw new Error(
                            `Profile id does not have a loaded version ${profile.idHash}`
                        );
                    }

                    addedNewTrust = true;
                    await this.leuteModel.trust.certify('TrustKeysCertificate', {
                        profile: profile.loadedVersion
                    });
                }
            }

            addedNew = true;
            MessageBus.send('debug', 'PatientModel - addToPatientsGroup', personId);
            patientsGroup.persons.push(personId);
        }

        if (addedNewTrust) {
            await this.leuteModel.trust.refreshCaches(); // Just a hack until we have a better way of refresh
        }

        if (addedNew) {
            await patientsGroup.saveAndLoad();
        }

        if ((emitChangeNonEmptyGroup && patientsGroup.persons.length > 0) || addedNew) {
            MessageBus.send('debug', 'PatientModel - addToPatientsGroup - has new patients');
            this.onPatientChange.emit();
        }

        return addedNew;
    }

    private async checkForPatientSignature(
        signatureResult: UnversionedObjectResult<Signature>
    ): Promise<void> {
        const relationCertificate = await getObject(signatureResult.obj.data);

        if (relationCertificate.$type$ !== 'RelationCertificate') {
            MessageBus.send(
                'debug',
                'PatientModel - emitOnNewPatient - signature listener - not relation cert',
                relationCertificate
            );
            return;
        }

        if (this.isPatientCertificate(relationCertificate)) {
            try {
                await this.leuteModel.getMainProfile(relationCertificate.person1);
            } catch (_e) {
                MessageBus.send(
                    'debug',
                    'PatientModel - emitOnNewPatient - signature listener - no profile found for user (yet?)',
                    relationCertificate
                );
                // catches cases where we have not received patient profile
                // see other waitForPatientDisconnectListeners
                // that handle the new profile case
                return;
            }

            // also emits on new patient
            await this.addToPatientsGroup([relationCertificate.person1]);
        }
    }
}
