import React from 'react';
import type {ReactElement} from 'react';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import Button from '@mui/material/Button/Button.js';
import TextField from '@mui/material/TextField/TextField.js';
import Typography from '@mui/material/Typography/Typography.js';

import './Login.css';

export default function Login(props: {
    onLogin: (email: string, password: string) => Promise<void>;
}): ReactElement {
    const i18n = useTranslation();
    const navigate = useNavigate();
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    return (
        <>
            <Typography className="login-header" component="h3">
                {i18n.t('flexibel_registration.login.header')}
            </Typography>
            <div className="login-content-container">
                <div className="login-content">
                    <div className="field">
                        <Typography className="login-field-label" component="div">
                            {i18n.t('flexibel_registration.login.label.username')}:
                        </Typography>
                        <TextField
                            type="text"
                            className="input"
                            placeholder="mail@adresse.de"
                            variant="outlined"
                            value={email}
                            onKeyPress={async event => {
                                if (email && password && event.key === 'Enter') {
                                    await props.onLogin(email.toLowerCase(), password);
                                }
                            }}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setEmail(event.target.value);
                            }}
                        />
                    </div>
                    <div className="field">
                        <Typography className="login-field-label" component="div">
                            {i18n.t('flexibel_registration.login.label.password')}:
                        </Typography>
                        <TextField
                            type="password"
                            className="input"
                            placeholder="************"
                            variant="outlined"
                            value={password}
                            onKeyPress={async event => {
                                if (email && password && event.key === 'Enter') {
                                    await props.onLogin(email.toLowerCase(), password);
                                }
                            }}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setPassword(event.target.value);
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="login-buttons">
                <Button
                    className="login"
                    disabled={!email || !password}
                    onClick={async () => {
                        if (email && password) {
                            await props.onLogin(email.toLowerCase(), password);
                        }
                    }}
                >
                    {i18n.t('flexibel_registration.buttons.common.login')}
                </Button>
                <Button onClick={() => navigate(-1)}>
                    {i18n.t('flexibel_registration.buttons.common.back')}
                </Button>
            </div>
        </>
    );
}
