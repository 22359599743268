import React from 'react';
import type {ReactElement} from 'react';
import {useState, useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import Button from '@mui/material/Button/Button.js';
import Checkbox from '@mui/material/Checkbox/Checkbox.js';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel.js';
import Typography from '@mui/material/Typography/Typography.js';

import AppBarFullScreenPopup from '@refinio/one.ui/lib/ui/components/appBar/AppBarFullScreenPopup.js';

import Markdown from '@/components/markdown/Markdown.js';

import {
    NOTIFICATION,
    useNotificationContext
} from '@/components/notification/SnackbarNotification.js';
import termsOfUseMarkdown from '@/locales/flexibel/de/MDFiles/termsofuse.md';
import privacyMarkdown from '@/locales/flexibel/de/MDFiles/privacypolicy.md';

/**
 * @param props.onNext callback
 * @param props.onBack callback
 * @param props.agreedTerms Optional. Default false
 * @param props.headerAddon Optional.
 * @returns
 */
export default function RegisterLegal(props: {
    onNext: (agreedTerms: boolean) => void;
    onBack: () => void;
    agreedTerms: boolean;
    headerAddon?: ReactElement;
}): ReactElement {
    const i18n = useTranslation();
    const [agreedTerms, setAgreedTerms] = useState<boolean>(props.agreedTerms);
    const {setNotificationMessage, setNotificationType} = useNotificationContext();
    const [startTranslation, middleTranslation, endTranslation] = i18n
        .t('flexibel_registration.register.legal.legalLabel')
        .split(/\{\{termsofuse}}|\{\{privacy}}/);

    const [isTermsofuseOpen, setIsTermsofuseOpen] = useState<boolean>(false);
    const toggleTermsofuse = useCallback(
        () => setIsTermsofuseOpen(old => !old),
        [setIsTermsofuseOpen]
    );
    const [isPrivacyOpen, setIsPrivacyOpen] = useState<boolean>(false);
    const togglePrivacy = useCallback(() => setIsPrivacyOpen(old => !old), [setIsPrivacyOpen]);

    function toggleTerms(_event: React.SyntheticEvent<Element, Event>, checked: boolean) {
        setAgreedTerms(checked);
    }

    function validate() {
        if (agreedTerms) {
            props.onNext(agreedTerms);
        } else {
            setNotificationMessage('flexibel_registration.register.legal.error.mustAgree');
            setNotificationType(NOTIFICATION.Error);
        }
    }

    return (
        <>
            {props.headerAddon}
            <Typography className="register-header" component="h3">
                {i18n.t('flexibel_registration.register.header')}
            </Typography>
            <div className="register-content-container">
                <div className="register-content space">
                    <div className="register-info">
                        <Typography className="register-field-label" component="div">
                            {i18n.t('flexibel_registration.register.legal.info')}
                        </Typography>
                    </div>
                    <FormControlLabel
                        className="consent-checkbox"
                        control={<Checkbox className="top" checked={agreedTerms} />}
                        label={
                            <>
                                <Typography component="span">{startTranslation}</Typography>
                                <div className="link" onClick={toggleTermsofuse}>
                                    {i18n.t('flexibel_registration.register.legal.termsofuse')}
                                </div>
                                <Typography component="span">{middleTranslation}</Typography>
                                <div className="link" onClick={togglePrivacy}>
                                    {i18n.t('flexibel_registration.register.legal.privacy')}
                                </div>
                                <Typography component="span">{endTranslation}</Typography>
                            </>
                        }
                        onChange={toggleTerms}
                        onKeyPress={event => {
                            if (event.key === 'Enter') {
                                validate();
                            }
                        }}
                    />
                </div>
            </div>
            <div className="register-buttons">
                <Button className="next" onClick={validate}>
                    {i18n.t('flexibel_registration.buttons.common.next')}
                </Button>
                <Button onClick={props.onBack}>
                    {i18n.t('flexibel_registration.buttons.common.back')}
                </Button>
            </div>
            {isTermsofuseOpen && (
                <AppBarFullScreenPopup
                    mode="chevron"
                    title={'Nutzungsbedingungen'}
                    onClose={toggleTermsofuse}
                >
                    <div className="markdown">
                        <Markdown url={termsOfUseMarkdown} />
                    </div>
                </AppBarFullScreenPopup>
            )}
            {isPrivacyOpen && (
                <AppBarFullScreenPopup
                    title={'Datenschutzerklärung'}
                    mode="chevron"
                    onClose={togglePrivacy}
                >
                    <div className="markdown">
                        <Markdown url={privacyMarkdown} />
                    </div>
                </AppBarFullScreenPopup>
            )}
        </>
    );
}
