import type {ReactElement} from 'react';
import {useState} from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';

import type {FlexibelRegistrationQuestionnaire} from '@/flexibel/hooks/questionnaire/flexibelRegistration.js';
import StageIndicator from '@/flexibel/components/stageIndicator/StagesIndicator.js';

import RegisterEnd from './RegisterEnd.js';
import RegisterLegal from './RegisterLegal.js';
import RegisterAccount from './RegisterAccount.js';
import RegisterPassword from './RegisterPassword.js';
import RegisterName from './RegisterName.js';
import RegisterImportKeys from './RegisterImportKeys.js';
import RegisterStart from './RegisterStart.js';

import './Register.css';

export default function RegisterRouter(props: {
    onRegister: (email: string, name: string, password: string, keys?: string) => Promise<void>;
    isRegistered: (email: string) => Promise<boolean>;
    registrationQuestionnaire: FlexibelRegistrationQuestionnaire;
}): ReactElement {
    const navigate = useNavigate();
    const [agreedTerms, setAgreedTerms] = useState<boolean>(false);
    const [email, setEmail] = useState<string | undefined>(undefined);
    const [name, setName] = useState<string | undefined>(undefined);
    const [keys, setKeys] = useState<string | undefined>(undefined);
    const [password, setPassword] = useState<string | undefined>(undefined);

    async function finishRegistration(): Promise<void> {
        if (
            agreedTerms === undefined ||
            email === undefined ||
            name === undefined ||
            password === undefined
        ) {
            return;
        }
        await props.onRegister(email, name, password, keys);
    }

    return (
        <Routes>
            <Route
                path="termsOfUse"
                element={
                    <RegisterLegal
                        agreedTerms={agreedTerms}
                        onNext={(newAgreedTerms: boolean) => {
                            setAgreedTerms(newAgreedTerms);
                            navigate('keys');
                        }}
                        onBack={() => navigate(-1)}
                        headerAddon={<StageIndicator stage={1} max={5} />}
                    />
                }
            />
            <Route
                path="keys"
                element={
                    <RegisterImportKeys
                        keys={keys}
                        onNext={newKeys => {
                            setKeys(newKeys);
                            navigate('name');
                        }}
                        onBack={() => navigate(-1)}
                        headerAddon={<StageIndicator stage={2} max={5} />}
                    />
                }
            />
            {agreedTerms && (
                <Route
                    path="name"
                    element={
                        <RegisterName
                            name={name}
                            onNext={registerName => {
                                setName(registerName);
                                navigate('account');
                            }}
                            onBack={() => navigate(-1)}
                            headerAddon={<StageIndicator stage={3} max={5} />}
                        />
                    }
                />
            )}
            {agreedTerms && name !== undefined && (
                <Route
                    path="account"
                    element={
                        <RegisterAccount
                            email={email}
                            onNext={async (newEmail: string) => {
                                if (!(await props.isRegistered(newEmail))) {
                                    setEmail(newEmail);
                                    navigate('password');
                                }
                            }}
                            onBack={() => navigate(-1)}
                            headerAddon={<StageIndicator stage={4} max={5} />}
                        />
                    }
                />
            )}
            {email !== undefined && name !== undefined && (
                <Route
                    path="password"
                    element={
                        <RegisterPassword
                            password={password}
                            onNext={(newPassword: string) => {
                                setPassword(newPassword);
                                navigate('end');
                            }}
                            headerAddon={<StageIndicator stage={5} max={5} />}
                        />
                    }
                />
            )}
            {email !== undefined && password !== undefined && name !== undefined && (
                <Route
                    path="end"
                    element={<RegisterEnd email={email} onNext={finishRegistration} />}
                />
            )}
            <Route path="*" element={<RegisterStart onNext={() => navigate('termsOfUse')} />} />
        </Routes>
    );
}
