import type {Dispatch, SetStateAction} from 'react';
import {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';

import type QuestionnaireModel from '@refinio/one.models/lib/models/QuestionnaireModel.js';
import type {
    Questionnaire,
    QuestionnaireValue
} from '@refinio/one.models/lib/models/QuestionnaireModel.js';
import QuestionnaireResponseBuilder from '@refinio/one.ui/lib/ui/views/utils/QuestionnaireResponseBuilder.js';

const ageQuestionLinkId = 'ageinclusion';
const birthYearQuestionLinkId = 'birthyear';
const ageQuestionYesCode = '1';
const ageQuestionNoCode = '2';

export type FlexibelRegistrationQuestionnaire = {
    isLegalAge: {
        text: string;
        value: boolean | undefined;
        set: Dispatch<SetStateAction<boolean | undefined>>;
    };
    birthYear: {
        text: string;
        value: number | undefined;
        set: Dispatch<SetStateAction<number | undefined>>;
    };
    post: () => Promise<void>;
};

export function useFlexibelRegistrationPrefillQuestionnaireValues(
    questionnaireModel: QuestionnaireModel,
    questionnaire: Questionnaire,
    _onError: (error: Error) => void = console.error
):
    | undefined
    | ((
          linkId: string,
          questionnaireUrl?: string
      ) => (QuestionnaireValue[] | void) | Promise<QuestionnaireValue[] | void>) {
    const questionnaireUrl = questionnaire.url
        ? questionnaire.url
        : 'http://refinio.one/questionaire/FlexibelRegistrierung';

    return useCallback(
        async (linkId: string) => {
            if (linkId === 'age') {
                let birthYear: string | undefined = undefined;
                for await (const responsesData of questionnaireModel.responsesIterator()) {
                    for (const response of responsesData.data.response) {
                        if (response.questionnaire === questionnaireUrl) {
                            birthYear = response.item.find(
                                i => i.linkId === birthYearQuestionLinkId
                            )?.answer[0].valueInteger;
                        }
                    }
                }

                if (birthYear !== undefined) {
                    const today = new Date();
                    const age = String(today.getFullYear() - parseInt(birthYear, 10));
                    return [
                        {
                            valueInteger: age
                        }
                    ];
                }
            }
        },
        [questionnaireModel, questionnaireUrl]
    );
}

export function useNewFlexibelRegistrationQuestionnaire(
    questionnaireModel: QuestionnaireModel,
    questionnaire: Questionnaire
): FlexibelRegistrationQuestionnaire {
    const questionnaireName = questionnaire.name ? questionnaire.name : 'flexibel_registrierung';
    const ageQuestion = questionnaire.item.filter(a => a.linkId === ageQuestionLinkId);
    const birthYearQuestion = questionnaire.item.filter(a => a.linkId === birthYearQuestionLinkId);

    const i18n = useTranslation();
    const [birthYear, setBirthYear] = useState<number | undefined>(undefined);
    const [isLegalAge, setIsLegalAge] = useState<boolean | undefined>(undefined);

    async function post(): Promise<void> {
        const questionnaireFromModel =
            await questionnaireModel.questionnaireByName(questionnaireName);
        const responseBuilder = new QuestionnaireResponseBuilder(questionnaireFromModel);

        for (const questionData of responseBuilder.questionIterator({
            hideDisabledValues: true,
            flatIteration: true
        })) {
            switch (questionData.question.linkId) {
                case ageQuestionLinkId:
                    {
                        if (!questionData.question.answerOption) {
                            return;
                        }

                        const builderAnswers = questionData.question.answerOption.filter(
                            v =>
                                v.valueCoding &&
                                ((isLegalAge && v.valueCoding.code === ageQuestionYesCode) ||
                                    (!isLegalAge && v.valueCoding.code === ageQuestionNoCode))
                        );
                        questionData.changeAnswer(builderAnswers);
                        questionData.completeAnswer();
                    }
                    break;
                case birthYearQuestionLinkId:
                    questionData.changeAnswer([{valueInteger: String(birthYear)}]);
                    questionData.completeAnswer();
                    break;
                default:
                    throw new Error(
                        `Flexibel Registration Questionnaire unexpected link id ${questionData.question.linkId}`
                    );
            }
        }

        await questionnaireModel.postResponse(responseBuilder.buildResponse());
    }

    function getAgeText(): string {
        if (ageQuestion.length > 0 && ageQuestion[0].text) {
            return ageQuestion[0].text;
        }
        return i18n.t('flexibel_registration.register.confirmAge.isLegalAge');
    }

    function getBirthYearText(): string {
        if (birthYearQuestion.length > 0 && birthYearQuestion[0].text) {
            return birthYearQuestion[0].text;
        }
        return i18n.t('flexibel_registration.register.birth.birthYear');
    }

    return {
        isLegalAge: {
            value: isLegalAge,
            set: setIsLegalAge,
            text: getAgeText()
        },
        birthYear: {
            value: birthYear,
            set: setBirthYear,
            text: getBirthYearText()
        },
        post
    };
}
